.singlework {
    /*Flex item:*/
    flex: 1 1 20rem;
    max-width: 21rem;
    background: #FFFFFF;
    color: #30292F;
    border-radius: 10px;
    border: 1px solid #e9e9d9;
    box-shadow: 1px 1px #FBFCFF;
    max-height: fit-content;
}
@media screen and (max-width: 767px) {
    .singlework {
        /*Flex item:*/
        flex: 1 1 17rem;
        max-width: 21rem;
        background: #FFFFFF;
        color: #30292F;
        border-radius: 10px;
        border: 1px solid #e9e9d9;
        box-shadow: 1px 1px #FBFCFF;
        max-height: fit-content;
    }
}
.single-name {
    cursor: pointer;
}
.singlework:hover .singleimage .sitepic {
    filter: saturate(1.35)
}
.singlework > div:nth-child(2) > div:nth-child(2):hover { /*Name*/
    color: #453e44;
    text-decoration: underline;
}
.singlework:hover {
    z-index: 1;
    position: static;
    background: #FFFFFF;
    border-right: 1px solid #e5e510;
    color: #30292F;
    border-radius: 10px;
    box-shadow: 2px 2px #e5e510, 0px 0px 36px 0px #4050251c;
}
.singleimage > div > .tooltip {
    display: none;
    visibility: hidden;
    width: fit-content;
    background-color: #405025f0;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 12px 8px 12px;
    /* Position the tooltip */
    position: relative;
    left: 30px;
    top: 2px;
    z-index: 1;
  }
.singleimage:hover .tooltip {
    display: block;
    visibility: visible;
}
