.works-title {
    padding-bottom: 100px;
}
button {
    margin-right: 12px;
    border-radius: 11px;
    padding-top: 0.55em;
    padding-bottom: 0.55em;
    padding-left: 0.9em;
    padding-right: 1em;
    /**/
    border: 1px solid #405025;
    background: #FBFCFF;
    color:  #405025;
    /**/
    font-size: 1.06rem;
}
button:hover {
    color: #30292F;
    border: 1px solid #30292F;
    box-shadow: 1px 1px #30292F;
    cursor: pointer;
}
.checkboxes-container {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.2rem;
    row-gap: 0.8rem;
}
@media screen and (max-width: 767px) {
    .checkbox {
        max-width: fit-content;
        max-height: fit-content;
        font-size: 5vw;
        display: inline;
    }
}
.checkbox {
    margin-right: 12px;
    display: inline;
    border-radius: 11px;
    padding-top: 0.55em;
    padding-bottom: 0.55em;
    padding-left: 0.9em;
    padding-right: 1em;
}
.checkbox.webapps:hover, .checkbox.landingpages:hover, .checkbox.spas:hover, .checkbox.statics:hover {
    color: #405025;
    background: #e9e9d9;
    border: 1px solid #405025;
    box-shadow: 1px 1px #405025;
    cursor: pointer;
}
.checkbox.webapps {
    border: 1px solid #e5e510;
    background: #e5e510;
    color: #405025;
}
.checkbox.landingpages {
    border: 1px solid #405025;
    background: #405025;
    color: #FBFCFF;
}
.checkbox.spas {
    border: 1px solid #0A1F14;
    background: #0A1F14;
    color: #FBFCFF;
}
.checkbox.statics {
    border: 1px solid #30292F;
    background: #30292F;
    color: #FBFCFF;
}
.checkbox > input, .checkbox > div {
    display: inline;
}
.checktext {
    padding-left: 0.65rem;
}
.works-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.1rem;
    row-gap: 1.1rem;
    /*not concerning flex:*/
    padding-top: 50px;
}



